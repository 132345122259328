import { authenticationKey } from '@cca-common/authentication';

// state keys
export const bookingKey = 'booking';
export const routerKey = 'router';
export const commonKey = 'booking';

// common config
export const commonConfig = {
  localStorageKeys: [authenticationKey, bookingKey],
  stateKeys: {
    booking: bookingKey,
    router: routerKey,
  },
};
